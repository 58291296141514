.progress-bar-container {
  width: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  height: 5px;
  border-radius: 0px;

  .progress-bar-inner {
    width: auto;
    transition: width .5s ease-in-out;
    background: var(--primary-color);
    height: 3px;
    border-radius: 0px;
    opacity: 0.2;
  }
}