.tab-bar {
  width: 100%;
  height: 80px;
  background-color: var(--background-color);
  box-shadow: 0px 0px 1px 1px rgba($color: #000000, $alpha: 0.05);
  position: fixed;
  bottom: 0px;
  left: 0px;
  right: 0px;
  z-index: 999;
  animation: fadeInUp;
  animation-duration: 0.4s;
  animation-fill-mode: forwards;

  display: flex;
  flex-direction: row;
  -webkit-box-pack: justify;
  justify-content: space-between;

  &-item {
    flex: 1 1 0%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    overflow: hidden;
    text-overflow: clip;
    text-align: center;
    transition: all 150ms ease 0s;

    .icon-container {
      position: relative;
      display: flex;
    }

    &--unread {
      width: 17px;
      height: 17px;
      border-radius: 17px;
      background-color: #da0e07;
      box-shadow: 0px 0px 0px 1.5px var(--background-color);
      position: absolute;
      right: -11px;
      top: -11px;
      color: #fff;
      font-weight: 600;
      font-size: 9px;
      text-align: center;
      line-height: 17px;
    }

    svg {
      height: 20px;
      width: auto;
      margin-bottom: 7px;
      margin-top: 4px;

      path {
        fill: var(--primary-text-color);
      }
    }

    span {
      font-size: 13px;
      line-height: 16px;
      font-weight: 400;
      color: var(--primary-text-color);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: calc(100% - 2px);
    }

    &.active,
    &:hover {
      svg {
        opacity: 1.0;

        path {
          fill: var(--primary-color);
        }
      }

      span {
        opacity: 1.0;
        color: var(--primary-color);
      }
    }
  }
}