.chat-message {
  padding: 24px;
  padding-top: 0px;
  padding-bottom: 6px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;

  .attachment-link {
    margin-top: 5px;
    color: var(--primary-color);
    font-size: 14px;
    font-weight: 600;
    line-height: 1.4;
    white-space: nowrap;
    overflow: hidden;
    display: block;
    text-overflow: ellipsis;
    max-width: 100%;
    cursor: pointer;

    svg {
      margin-right: 3px;
    }
  }

  .attachment-image {
    width: 100%;
    height: auto;
    object-fit: contain;
    margin-top: 5px;
    border-radius: var(--border-radius-25);
    cursor: pointer;
  }

  .message-container {
    margin-left: 44px;
    max-width: calc(93% - 32px);
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .message {
      background-color: var(--background-color-darker);
      box-sizing: border-box;
      border-radius: var(--border-radius-60);
      padding: 17px 20px;
      color: var(--primary-text-color);
      font-size: 14px;
      font-weight: 400;
      line-height: 1.4;
      max-width: 100%;

      .message-text {
        overflow-wrap: break-word;
        line-height: 1.4;

        > :first-child {
          margin-top: 0px !important;
        }

        a,
        p {
          color: var(--primary-text-color);
          font-size: 14px;
          font-weight: 400;
          line-height: 1.4;
          overflow-wrap: break-word;
          word-wrap: break-word;
          -ms-word-break: break-all;
          word-break: break-all;
          word-break: break-word;
        }

        a {
          color: var(--primary-color);
          text-decoration: underline;
        }

        ul {
          padding-left: 15px;
          margin-top: 10px;
          margin-bottom: 10px;

          li {
            padding-top: 1px;
            padding-bottom: 1px;
          }
        }

        .linktype-button {
          margin-top: 10px;
          margin-bottom: 10px;
        }

        img {
          width: 100%;
          height: 100%;
          max-width: 100%;
          max-height: 500px;
          object-fit: contain;
        }

        h1,
        h2 {
          margin-top: 10px;
          margin-bottom: 3px;
          font-weight: bold;
          font-size: 18px;
          line-height: 1.4;
          font-weight: 500;
          color: var(--primary-text-color);

          span {
            font-size: 18px;
            line-height: 1.4;
            font-weight: 500;
            color: var(--primary-text-color);
          }
        }

        h3 {
          margin-top: 10px;
          margin-bottom: 3px;
          font-weight: bold;
          font-size: 16px;
          line-height: 1.4;
          font-weight: 500;
          color: var(--primary-text-color);

          span {
            font-size: 16px;
            line-height: 1.4;
            font-weight: 500;
            color: var(--primary-text-color);
          }
        }

        iframe {
          width: 100%;
          border: 0px !important;
          height: 300px;
          margin-top: 10px;
          margin-bottom: 10px;
        }

        .iframe-wrapper {
          position: relative;
          padding-bottom: 62.5%;
          height: 0;

          iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
          }
        }

        p {
          font-size: 14px;
          font-weight: 400;
          line-height: 1.4;
          margin-top: 3px;
          margin-bottom: 3px;
        }

        code {
          background: none;
          color: inherit;
          font-size: 0.8rem;
          padding: 0;
          font-family: "JetBrainsMono", monospace;
          background-color: var(--border-color-55);
          color: var(--primary-text-color);
          padding: 2px 4px;
          border-radius: 5px;
        }

        pre {
          background: #0d0d0d;
          border-radius: 0.5rem;
          color: #fff;
          font-family: "JetBrainsMono", monospace;
          padding: 0.75rem 1rem;
          font-size: 14px;
          line-height: 18px;
          max-width: 100%;
          overflow: auto;
          margin-top: 10px;
          margin-bottom: 10px;

          code {
            background: none;
            color: inherit;
            font-size: 0.8rem;
            padding: 0;
            font-family: "JetBrainsMono", monospace;
          }
        }
      }

      .message-form-item {
        margin-bottom: 8px;

        &-title {
          font-size: 14px;
          line-height: 1.4;
          opacity: 0.6;
        }

        &-value {
          font-size: 14px;
          font-weight: 400;
          line-height: 1.4;
          word-wrap: break-word;
        }

        &:last-of-type {
          margin-bottom: 0px;
        }
      }
    }

    .chat-message-image {
      margin-top: 3px;
      cursor: pointer;

      img {
        width: auto;
        height: auto;
        max-width: 100%;
        max-height: 260px;
        border-radius: var(--border-radius-50);
        border: 1px solid var(--border-color-33);
      }
    }
  }

  &--typing {
    .avatar {
      margin-bottom: 5px;
    }
  }

  &--featurerequest-duplicates {
    .message-container {
      width: 100%;
    }

    .message {
      background-color: var(--background-color) !important;
      border: 1px solid var(--border-color-55);
      padding: 8px !important;
      width: 100%;
      box-shadow: 0px 0px 10px 0px rgba($color: #000000, $alpha: 0.07);
    }
  }

  &--aibot {
    .message {
      background-color: var(--background-color) !important;
      border: 1px solid var(--border-color-55);
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.07);
    }
  }

  &--last-of-group {
    padding-bottom: 16px;

    .message-container {
      margin-left: 12px;
    }

    .avatar {
      display: flex;
    }
  }

  &--creator {
    justify-content: flex-end;

    .message-container {
      margin-left: 0px;
      align-items: flex-end;

      .message {
        background-color: var(--primary-color);
        color: var(--primary-contrast-color);

        .message-text,
        .message-text a,
        .message-text p,
        p {
          color: var(--primary-contrast-color);
        }
      }
    }

    .avatar {
      display: none;
    }
  }
}

.dot-flashing {
  position: relative;
  width: 8px;
  height: 8px;
  margin-left: 8px;
  margin-right: 8px;
  border-radius: 5px;
  background-color: var(--primary-text-color);
  color: var(--primary-text-color);
  animation: dot-flashing 1s infinite linear alternate;
  animation-delay: 0.5s;
}

.dot-flashing::before,
.dot-flashing::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
}

.dot-flashing::before {
  left: -12px;
  width: 8px;
  height: 8px;
  border-radius: 5px;
  background-color: var(--primary-text-color);
  color: var(--primary-text-color);
  animation: dot-flashing 1s infinite alternate;
  animation-delay: 0s;
}

.dot-flashing::after {
  left: 12px;
  width: 8px;
  height: 8px;
  border-radius: 5px;
  background-color: var(--primary-text-color);
  color: var(--primary-text-color);
  animation: dot-flashing 1s infinite alternate;
  animation-delay: 1s;
}

@keyframes dot-flashing {
  0% {
    background-color: var(--primary-text-color-99);
  }

  50%,
  100% {
    background-color: var(--primary-text-color-11);
  }
}

.message-date {
  margin-top: 4px;
  font-size: 12px;
  font-weight: 400;
  color: var(--secondary-text-color);
}

.bot-input-reply {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;

  &-label {
    font-size: 14px;
    font-weight: 400;
    line-height: 1.3;
    margin-bottom: 8px;
    color: var(--primary-text-color);
  }

  &-rating-response {
    display: flex;
    justify-content: center;
    margin-top: 10px;
    margin-bottom: 10px;

    &-option {
      margin-left: 5px;
      margin-right: 5px;
      font-size: 34px;
      transition: all 0.2s ease-in-out;
      cursor: not-allowed;
      transform: none;
      filter: grayscale(100%);

      &--selected {
        cursor: pointer;
        filter: none;
        transform: scale(1.2);
      }
    }
  }

  &-response {
    width: 100%;
    font-size: 14px;
    line-height: 1.3;
    appearance: none;
    padding: 11px 12px;
    word-break: break-word;
    outline: 0;
    flex-grow: 1;
    color: var(--primary-text-color);
    border: 1px solid var(--border-color-33);
    background-color: var(--background-color);
    border-radius: var(--border-radius-50);
    box-sizing: border-box;
    font-weight: 400;
    cursor: not-allowed;
    box-shadow: inset 0 0 5px 5px rgba(0, 0, 0, 0.01);
  }

  &-check {
    position: absolute;
    bottom: 13px;
    right: 15px;
    width: 15px;
    height: auto;

    path {
      fill: #198754 !important;
    }
  }
}

.link-sources {
  margin-left: -20px;
  margin-right: -20px;
  margin-bottom: -17px;
  padding: 12px 12px;
  margin-top: 17px;
  border-top: 1px solid var(--border-color-55);
  box-sizing: border-box;
  border-radius: 0px 0px var(--border-radius-50) var(--border-radius-50);
  color: var(--primary-text-color);

  &-header {
    margin-bottom: 0px;
    font-weight: 600;
    padding: 10px 0px;
    padding-left: 8px;
  }

  &-links {
    display: flex;
    flex-direction: column;

    .link-source {
      min-height: 36px;
      min-width: 200px;
      display: flex;
      align-items: center;
      cursor: pointer;
      padding: 8px 13px;
      padding-left: 8px;
      border-radius: 10px;

      &--arrow {
        margin-left: 8px;
        display: block;
        color: var(--primary-color);
        font-size: 12px;
      }

      &--submiticon {
        height: 27px;
        width: 40px;
        flex-shrink: 0;
        background: #eee;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 8px;
        margin-right: 8px;
        font-size: 12px;
        background-color: var(--primary-color-11);
        color: var(--primary-color);

        svg {
          color: var(--primary-color) !important;
        }
      }

      &--upvotes {
        width: 40px;
        height: 27px;
        flex-shrink: 0;
        background: #eee;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 8px;
        margin-right: 8px;
        font-size: 11px;
        background-color: var(--primary-color-11);
        color: var(--primary-color);

        svg {
          width: 7px;
          margin-right: 3px;
          fill: var(--primary-color);
        }
      }

      &--title {
        color: var(--primary-text-color);
        font-weight: 400;
        flex-grow: 1;
        font-size: 14px;
        line-height: 1.5;
        margin-right: 10px;
        max-width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      &:hover {
        background-color: var(--primary-color-11);

        .link-source {
          &--title {
            color: var(--primary-color) !important;
          }

          &--arrow {
            fill: var(--primary-color) !important;
          }

          &--upvotes {
            background-color: var(--primary-color);
            color: var(--background-color);

            svg {
              fill: var(--background-color);
            }
          }

          &--submiticon {
            background-color: var(--primary-color);
            color: var(--background-color);

            svg {
              color: var(--background-color) !important;
            }
          }
        }
      }
    }
  }
}

.answer-bot {
  color: var(--primary-text-color);
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 8px;

  &-tag {
    display: inline-block;
    margin-right: 4px;
    padding: 4px 6px;
    border-radius: 4px;
    background-color: var(--primary-text-color);
    color: var(--primary-contrast-color);
    font-size: 12px;
    font-weight: 700;
  }
}