.form-item {
  display: flex;
  flex-direction: column;
  margin-bottom: 25px;

  &:last-of-type {
    margin-bottom: 0px;
  }

  &-description {
    font-size: 15px;
    line-height: 21px;
    color: var(--secondary-text-color);
    font-weight: normal;
    margin-bottom: 15px;
    margin-top: -10px;
  }

  &-label {
    font-size: 15px;
    line-height: 23px;
    text-align: left;
    font-weight: 500;
    margin-bottom: 8px;
    margin-top: 0px;
    color: var(--primary-text-color);

    &--required {
      color: #ed4337;
      margin-left: 2px;
    }
  }
}

[dir="rtl"] .form-item-label {
  text-align: right;
}

.main-app--survey-full,
.main-app--survey {
  .form-item-label {
    font-size: 17px;
    margin-bottom: 10px;
    display: block;
  }

  .form-item-description {
    margin-top: -10px;
    margin-bottom: 20px;
    display: block;
  }

  .form-item:first-of-type .form-item-label {
    padding-right: 30px;
  }
}
