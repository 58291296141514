.bot-action-chat-message {
  max-width: calc(100% - 12px);
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  padding-top: 0px;
  margin-left: 12px;

  &-container {
    padding: 24px;
    padding-top: 10px;
    padding-bottom: 20px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;

    &:last-of-type {
      padding-bottom: 34px;
    }

    .avatar {
      display: flex;
    }

    &--hide-avatar {
      .avatar {
        opacity: 0;
      }
    }
  }

  &-inner {
    background-color: var(--background-color);
    box-sizing: border-box;
    border-radius: var(--border-radius-50);
    padding: 18px 20px;
    color: var(--primary-text-color);
    border-top: 2px solid var(--primary-color-55);
    font-size: 14px;
    font-weight: 400;
    line-height: 1.4;
    max-width: 100%;
    width: 100%;
    box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.1);
  }

  .message-date {
    margin-top: 8px;
  }
}
