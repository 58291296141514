.nps-form-item {
  &-items {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 0px;

    @media screen and (max-device-width: 600px) {
      justify-content: center;
    }
  }

  &-item {
    cursor: pointer;
    padding: 0;
    border: 1px solid var(--primary-color-44);
    background-color: var(--primary-color-07);
    color: var(--primary-color);
    border-radius: var(--border-radius-25);
    text-align: center;
    margin: 3px;
    width: 42px;
    min-width: 42px;
    max-width: 42px;
    line-height: 40px;
    min-height: 42px;
    max-height: 42px;
    font-size: 17px;
    font-weight: 500;

    @media screen and (max-device-width: 600px) {
      margin: 4px;
      width: calc(16.6% - 8px);
      min-width: calc(16.6% - 8px);
      max-width: calc(16.6% - 8px);
      line-height: 40px;
      min-height: 42px;
      max-height: 42px;
    }

    &:hover,
    &--active {
      background-color: var(--primary-color-11);
      border: 1px solid var(--primary-color);
      color: var(--primary-color);
    }
  }

  &-emoji {
    .nps-form-item-item {
      margin: 4px;
      width: calc(20% - 8px);
      min-width: calc(20% - 8px);
      max-width: calc(20% - 8px);
      line-height: 50px;
      min-height: 52px;
      max-height: 52px;

      span {
        font-size: 20px;
      }
    }
  }

  &-modern {
    .nps-form-item-item {
      margin: 4px;
      width: calc(16.6% - 8px);
      min-width: calc(16.6% - 8px);
      max-width: calc(16.6% - 8px);
      line-height: 50px;
      min-height: 52px;
      max-height: 52px;

      span {
        font-size: 20px;
      }
    }
  }

  &-labels {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 10px;

    span {
      color: var(--secondary-text-color);
      font-size: 13px;
      font-weight: 500;
      text-align: start;

      &:last-of-type {
        text-align: end;
      }
    }
  }
}

.main-app--survey {
  .nps-form-item-items {
    justify-content: center;
    max-width: 340px;
    margin-top: 2px;
    margin-left: auto;
    margin-right: auto;
  }
}

.main-app--survey-full {
  .nps-form-item-item {
    @media screen and (min-device-width: 600px) {
      margin: 3px;
      width: calc(9.09% - 6px);
      min-width: calc(9.09% - 6px);
      max-width: calc(9.09% - 6px);
      line-height: 40px;
      min-height: 42px;
      max-height: 42px;
    }
  }

  .nps-form-item.nps-form-item-modern,
  .nps-form-item.nps-form-item-emoji {
    .nps-form-item-item {
      @media screen and (min-device-width: 600px) {
        margin: 4px;
        width: calc(15% - 8px);
        min-width: calc(15% - 8px);
        max-width: calc(15% - 8px);
        line-height: 50px;
        min-height: 52px;
        max-height: 52px;

        span {
          font-size: 20px;
        }
      }
    }
  }
}