.form-send-button {
  background-color: var(--primary-color);
  color: var(--primary-contrast-color);
  border-radius: var(--border-radius-60);
  box-sizing: border-box;
  padding: 11px 26px;
  font-size: 16px;
  line-height: 19px;
  font-weight: 600;
  border: none;
  text-align: center;
  width: 100%;
  margin-top: 25px;
  margin-bottom: 0;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    display: none;
  }

  &--disabled {
    background-color: var(--background-color-darker) !important;
    color: var(--background-color-dark) !important;
    opacity: 1 !important;
    cursor: not-allowed !important;
  }
}

.main-app--survey,
.main-app--survey-full {
  .form-send-button {
    width: auto;
    margin: auto;
    margin-top: 25px;
  }
}