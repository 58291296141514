.gleap-loading {
  display: inline-block;
  position: relative;
  width: 48px;
  height: 48px;
}
.gleap-loading div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 48px;
  height: 48px;
  margin: 0px;
  border-width: 3px;
  border-style: solid;
  border-radius: 50%;
  animation: gleap-loading-kf 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: transparent;
  border-bottom-color: var(--primary-text-color-11);
}
.gleap-loading div:nth-child(1) {
  animation-delay: -0.45s;
}
.gleap-loading div:nth-child(2) {
  animation-delay: -0.3s;
}
.gleap-loading div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes gleap-loading-kf {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}