.app-view {
  display: flex;
  height: calc(100vh - 136px);
  width: 100%;

  iframe {
    height: 100%;
    width: 100%;
    border: 0px;
    margin: 0px;
    padding: 0px;
  }

  &--loading {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;

    iframe {
      opacity: 0;
      height: 0px;
      width: 0px;
    }

    .gleap-loading {
      width: 34px;
      height: 34px;
    }
    .gleap-loading div {
      width: 34px;
      height: 34px;
      border-width: 3px;
    }
  }
}

.main-app--tabbar--hidden .app-view {
  height: calc(100vh - 60px);
}
