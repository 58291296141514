.upload-form-item {
  display: flex;
  justify-content: flex-start;

  &-loading {
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    border: 1px dashed var(--primary-color-44);
    background-color: var(--primary-color-07);
    color: var(--primary-color);
    border-radius: var(--border-radius-50);
  }

  .dropzone {
    width: 100%;
    text-align: center;
    padding: 30px 20px;
    cursor: pointer;
    border: 1px dashed var(--primary-color-44);
    background-color: var(--primary-color-07);
    color: var(--primary-color);
    border-radius: var(--border-radius-50);

    &--accept {
      border-color: var(--primary-color) !important;
      background-color: var(--primary-color-20) !important;
      color: var(--secondary-text-color);
    }

    &--reject {
      border-color: #d83b01 !important;
    }
  }

  .file-added-button {
    border-radius: var(--border-radius-50);
    background-color: var(--primary-color-11);
    box-sizing: border-box;
    padding: 6px 12px;
    padding-right: 8px;
    width: 100%;
    margin-top: 5px;
    margin-right: 8px;
    margin-bottom: 0px;
    -webkit-tap-highlight-color: transparent;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    height: 48px;

    svg {
      width: 19px;
      height: 19px;
      object-fit: contain;
      flex-shrink: 0;

      path,
      g {
        fill: var(--primary-color);
      }
    }

    span {
      font-size: 14px;
      line-height: 19px;
      font-weight: 600;
      color: var(--primary-text-color);
      font-weight: 400;
      margin-left: 8px;
      flex-grow: 1;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &:last-of-type {
      margin-right: 0px;
    }

    &-remove {
      cursor: pointer;
      flex-shrink: 0;
      margin-left: 5px;
      width: 27px;
      height: 27px;
      border-radius: var(--border-radius-25);
      display: flex;
      justify-content: center;
      align-items: center;

      @media (hover: hover) {
        &:hover {
          background-color: var(--primary-color-22);
        }
      }
    }
  }
}

[dir="rtl"] .upload-form-item .file-added-button span {
  margin-left: 0px !important;
  margin-right: 8px;
}

[dir="rtl"] .upload-form-item .file-added-button {
  padding-right: 12px !important;
  padding-left: 8px;
}

[dir="rtl"] .file-added-button-remove {
  margin-left: 0px !important;
  margin-right: 5px;
}
