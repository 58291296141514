* {
  font-family: system-ui, "Segoe UI", Roboto, Helvetica, Arial, sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 100%;
  line-height: 1;
  padding: 0px;
  margin: 0px;
  box-sizing: border-box;
  -webkit-text-size-adjust: none;
  -webkit-font-smoothing: antialiased;
}

body,
html {
  font-size: 16px;
  overflow-x: hidden;
  overflow-y: auto;
}

body.animating,
html.animating {
  overflow-x: hidden;
  overflow-y: hidden !important;
}

.grecaptcha-badge {
  display: none !important;
  opacity: 0 !important;
}

// Helpcenter article
.helpcenter-conversation-article {
  padding: 12px 14px;
  border-radius: var(--border-radius-50);
  box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.25), 0px 4px 8px 0px rgba(0, 0, 0, 0.04);
  max-width: 500px;
  cursor: pointer;
  margin-top: 10px;
  margin-bottom: 5px;
  border: 1px solid transparent;
  background-color: var(--background-color);

  .article-header-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .article-header {
      font-size: 14px;
      line-height: 1.3;
      color: var(--primary-text-color);
      font-weight: bold;
    }
  }

  .article-description {
    font-size: 14px;
    line-height: 1.25;
    color: var(--secondary-text-color);
    margin-top: 5px;
    display: -webkit-box;
    max-width: 100%;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  &:hover {
    .article-header {
      color: var(--primary-color) !important;
    }
  }
}
