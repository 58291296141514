html,
body {
  height: 100%;
  min-height: 100%;
}

#root {
  height: 100%;
  min-height: 100%;
}

.main-app {
  overflow: hidden;
  height: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;

  &--main {
    overflow: auto;

    background: linear-gradient(
      180deg,
      var(--header-color) 0%,
      var(--header-color) 60px,
      var(--background-color) 60px,
      var(--background-color) 100%
    );
  }
}

.main-app--tabbar--hidden {
  .tab-bar {
    animation: fadeOutDown;
    animation-duration: 0.4s;
    animation-fill-mode: forwards;
  }
}

.content-container {
  flex-grow: 1;
}

.main-app--survey-full {
  overflow-y: auto;
  height: auto !important;
}

.main-app--survey,
.main-app--survey-full {
  .content-container {
    background-color: var(--background-color);
    overflow-y: auto;
  }
}

.main {
  display: grid;
  grid-template: "main";
  flex: 1;
  position: relative;
  overflow: hidden;
}

.main > * {
  grid-area: main; /* Transition: make sections overlap on same cell */
  flex: 1 1 auto;
  position: relative;
}

.main > :first-child {
  z-index: 1; /* Prevent flickering on first frame when transition classes not added yet */
}

.mr-5 {
  margin-right: 5px;
}

.mr-8 {
  margin-right: 8px;
}

.ml-8 {
  margin-left: 8px;
}

@keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translate3d(100%, 0, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeOutDown {
  from {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }

  to {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes menuFadeIn {
  0% {
    opacity: 0;
  }

  70% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeInSub {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.linktype-button {
  background-color: var(--primary-color) !important;
  color: var(--primary-contrast-color) !important;
  border-radius: var(--border-radius-50) !important;
  box-sizing: border-box;
  padding: 12px 26px;
  font-size: 16px;
  line-height: 19px;
  font-weight: 500 !important;
  border: none;
  text-align: center;
  text-decoration: none;
  width: auto;
  margin-top: 5px;
  margin-bottom: 0;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.skeleton-box {
  display: inline-block;
  height: 1em;
  position: relative;
  overflow: hidden;
  background-color: var(--background-color-darker);
  border-radius: 4px;

  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background-image: linear-gradient(
      90deg,
      rgba(var(--background-color), 0) 0,
      rgba(var(--background-color), 0.2) 20%,
      rgba(var(--background-color), 0.5) 60%,
      rgba(var(--background-color), 0)
    );
    animation: shimmer 2s infinite;
    content: "";
  }

  @keyframes shimmer {
    100% {
      transform: translateX(100%);
    }
  }
}

.action-overlay {
  text-align: center;
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;
  z-index: 99;
  padding: 36px;
  padding-bottom: 96px;
  padding-top: 0px;
  background: linear-gradient(0deg, var(--background-color), transparent);
  display: flex;
  justify-content: center;
}

.action-button {
  width: auto;
  padding: 11px 22px;
  font-size: 14px;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 12px;

  &:hover {
    box-shadow: rgba(0, 0, 0, 0.25) 0px 4px 18px;
  }

  svg {
    display: inline-block;
    fill: var(--primary-contrast-color);
    width: 13px;
    height: auto;
    margin-right: 10px;
  }
}
