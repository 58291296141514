.chat-message-composer {
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background-color: var(--background-color);
  box-shadow: rgba(0, 0, 0, 0.02) -1px -4px 10px 3px;
  border-top: 1px solid var(--border-color-33);
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  animation: menuFadeIn;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;

  textarea {
    width: calc(100% - 98px);
    border: none;
    resize: none;
    outline: 0;
    appearance: none;
    padding: 20px 25px;
    padding-right: 0px;
    line-height: 19px;
    font-size: 14px;
    box-sizing: border-box;
    color: var(--primary-text-color);
    font-weight: 400;
    background-color: var(--background-color);

    @media screen and (max-device-width: 600px) {
      font-size: 16px;
    }

    &::placeholder {
      color: var(--primary-text-color);
      opacity: 0.4;
    }
  }

  .composer-buttons {
    display: flex;

    .attachment-button {
      cursor: pointer;
      width: 22px;
      height: 22px;
      margin-left: 18px;
      margin-right: 0px;
      margin-bottom: 17.5px;
      opacity: 0.7;
      fill: var(--primary-text-color);

      @media (hover: hover) {
        &:hover {
          opacity: 0.5;
        }
      }

      &--fileselected {
        fill: var(--primary-color);
      }
    }

    .send-button {
      cursor: pointer;
      width: 22px;
      height: 22px;
      margin-left: 18px;
      margin-right: 18px;
      margin-bottom: 17.5px;
      fill: var(--primary-color);

      @media (hover: hover) {
        &:hover {
          opacity: 0.8;
        }
      }

      &--disabled {
        fill: var(--background-color-dark);
      }
    }
  }
}
