.bot-action-text-input {
  display: flex;
  flex-direction: column;
  width: 100%;
  display: flex;

  &-label {
    font-size: 14px;
    font-weight: 400;
    line-height: 1.3;
    margin-bottom: 8px;
    color: var(--primary-text-color);
  }

  &-innercontainer {
    width: 100%;
    display: flex;

    input {
      width: 100%;
      font-size: 14px;
      line-height: 1.3;
      appearance: none;
      padding: 11px 12px;
      padding-right: 5px;
      outline: 0;
      flex-grow: 1;
      color: var(--primary-text-color);
      border: 1px solid var(--border-color);
      background-color: var(--background-color);
      box-sizing: border-box;
      font-weight: 400;
      border-radius: var(--border-radius-50) 0px 0px var(--border-radius-50);

      &:focus {
        outline: none !important;
        border: 1px solid var(--primary-color-AA) !important;
        border-right: none !important;
      }

      &::placeholder {
        color: var(--primary-text-color);
        opacity: 0.4;
      }

      &--invalid,
      &--invalid:focus {
        border: 1px solid #ed4337;
        border-right: none !important;
      }

      @media screen and (max-device-width: 600px) {
        font-size: 16px;
      }

      border-right: none !important;
    }

    button {
      background-color: var(--primary-color-22);
      border: 1px solid var(--primary-color);
      color: var(--primary-color);
      border-radius: 0px var(--border-radius-50) var(--border-radius-50) 0px;
      box-sizing: border-box;
      padding: 10px 17px;
      text-align: center;
      width: auto;
      cursor: pointer;
      -webkit-tap-highlight-color: transparent;
      display: flex;
      justify-content: center;
      align-items: center;
      min-width: 40px;
      flex-shrink: 0;

      svg {
        width: 10px;
        height: auto;
        fill: var(--primary-color);
      }

      &:hover {
        background-color: var(--primary-color);
        border: 1px solid var(--primary-color);
        color: var(--primary-contrast-color);

        svg {
          fill: var(--primary-contrast-color);
        }
      }

      &.button--disabled {
        background-color: var(--background-color-darker) !important;
        color: var(--background-color-dark) !important;
        opacity: 1 !important;
        border: 1px solid var(--background-color-dark);
        cursor: not-allowed !important;

        svg {
          fill: var(--background-color-dark);
        }
      }
    }
  }
}
