.news-details {
  height: 100vh;
  overflow: auto;
  background-color: var(--background-color);
  z-index: 2;
  position: relative;

  .news-details-loading {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 40px;

    .gleap-loading {
      width: 34px;
      height: 34px;
    }

    .gleap-loading div {
      width: 34px;
      height: 34px;
      border-width: 3px;
    }
  }

  .back-button {
    position: fixed;
    top: 8px;
    left: 8px;
    z-index: 10;

    .icon-button {
      background-color: var(--background-color-99);
      border-radius: var(--border-radius-60);
      width: 44px;
      height: 44px;

      svg path {
        fill: var(--primary-color);
      }

      @media (hover: hover) {
        &:hover {
          background-color: var(--primary-color-22) !important;

          svg path {
            fill: var(--primary-color);
          }
        }
      }
    }
  }

  .news-title {
    color: var(--primary-text-color);
    padding: 24px;
    padding-bottom: 0px;
    padding-top: 40px;
    font-size: 27px;
    line-height: 1.4;
    font-weight: 700;
    animation: menuFadeIn;
    animation-duration: 0.45s;
    max-width: 550px;
    margin: auto;

    @media only screen and (max-width: 660px) {
      font-size: 22px;
      padding-top: 20px;
    }
  }

  .news-header {
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background-color: var(--background-color);

    span {
      transition: all 0.2s ease-in-out;
      opacity: 0;
      color: var(--primary-text-color);
      line-height: 21px;
      font-size: 15px;
      font-weight: 700;
      padding-left: 10px;
      text-align: left;
      display: block;
      padding-left: 60px;
      max-width: calc(100% - 60px);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &--placeholder {
      height: 60px;
    }

    &--shadow {
      box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 14px;
    }

    &--title {
      span {
        opacity: 1;
      }
    }
  }

  .news-content-skeleton {
    padding: 24px;
    padding-bottom: 0px;
    padding-top: 40px;
    animation: menuFadeIn;
    animation-duration: 0.45s;
    max-width: 550px;
    margin: auto;
  }

  .news-content-image {
    width: 100%;
    height: auto;
    max-height: 330px;
    object-fit: cover;
  }

  .news-content {
    max-width: 550px;
    margin: auto;
    padding: 24px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    @media only screen and (min-width: 660px) {
      padding-bottom: 60px;
    }

    &-meta {
      display: flex;
      align-items: center;
      margin-bottom: 30px;

      img {
        width: 25px;
        height: 25px;
        margin-right: 12px;
        border-radius: 100%;
      }

      span {
        color: var(--secondary-text-color);
        font-size: 14px;
        line-height: 21px;
        font-weight: 400;
      }
    }

    &-body {
      > :first-child {
        margin-top: 0px !important;
      }

      p {
        min-height: 14px;
      }

      a,
      p,
      span {
        color: var(--primary-text-color);
        font-size: 16px;
        font-weight: 400;
        line-height: 1.5;
        overflow-wrap: break-word;
        word-wrap: break-word;
        -ms-word-break: break-all;
        word-break: break-all;
        word-break: break-word;
      }

      strong * {
        font-weight: 600;
      }

      a {
        color: var(--primary-color);
        text-decoration: underline;
      }

      ul {
        padding-left: 15px;
        margin-top: 20px;
        margin-bottom: 20px;
      }

      .linktype-button {
        margin-top: 10px;
        margin-bottom: 10px;
      }

      img {
        width: 100%;
        height: 100%;
        max-width: 100%;
        max-height: 500px;
        object-fit: contain;
      }

      h1,
      h2 {
        margin-top: 30px;
        margin-bottom: 10px;
        font-weight: bold;
        font-size: 19px;
        line-height: 1.4;
        font-weight: 500;
        color: var(--primary-text-color);

        span {
          font-size: 19px;
          line-height: 1.4;
          font-weight: 500;
          color: var(--primary-text-color);
        }
      }

      h3 {
        margin-top: 25px;
        margin-bottom: 10px;
        font-weight: bold;
        font-size: 17px;
        line-height: 1.4;
        font-weight: 500;
        color: var(--primary-text-color);

        span {
          font-size: 17px;
          line-height: 1.4;
          font-weight: 500;
          color: var(--primary-text-color);
        }
      }

      iframe {
        width: 100%;
        border: 0px !important;
        height: 300px;
        margin-top: 10px;
        margin-bottom: 10px;
      }

      .iframe-wrapper {
        position: relative;
        padding-bottom: 62.5%;
        height: 0;

        iframe {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
      }

      p {
        font-size: 16px;
        font-weight: 400;
        line-height: 1.5;
        margin-top: 10px;
        margin-bottom: 10px;
      }

      pre {
        background: #0d0d0d;
        border-radius: 0.5rem;
        color: #fff;
        font-family: "JetBrainsMono", monospace;
        padding: 0.75rem 1rem;
        font-size: 14px;
        line-height: 18px;
        max-width: 100%;
        overflow: auto;
        margin-top: 20px;
        margin-bottom: 20px;

        code {
          background: none;
          color: inherit;
          font-size: 0.8rem;
          padding: 0;
          font-family: "JetBrainsMono", monospace;
        }
      }
    }
  }
}