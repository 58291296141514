.conversation-item {
  padding: 18px 20px;
  padding-right: 15px;
  padding-bottom: 0px;
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  background-color: var(--background-color);
  box-sizing: border-box;
  position: relative;

  &:last-of-type {
    margin-bottom: 160px;
  }

  @media (hover: hover) {
    &:hover {
      background-color: var(--primary-color-11);

      .conversation-item-title {
        color: var(--primary-color);
      }

      #botbg {
        fill: var(--primary-color-22) !important;
      }

      .botclip,
      #botclip {
        fill: var(--primary-color) !important;
      }
    }
  }

  &--inner {
    padding-bottom: 18px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    border-bottom: 1px solid var(--border-color-33);
  }

  &--unread {
    .conversation-item-labels .conversation-item-title {
      font-weight: 600;
    }

    .conversation-item-unreaddot {
      width: 8px;
      height: 8px;
      border-radius: 8px;
      background-color: #da0e07;
      flex-shrink: 0;
    }
  }

  &-avatar {
    width: 38px;
    height: 38px;
    min-width: 38px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;

    .operator-info-team-image {
      width: 38px;
      height: 38px;
      flex-shrink: 0;
      position: relative;

      img {
        width: calc(18px + 4px);
        height: calc(18px + 4px);
        border-radius: 100%;
        object-fit: cover;
        border: 2px solid var(--background-color);
        background-color: var(--background-color);
      }

      img:nth-of-type(1) {
        position: absolute;
        top: 0px;
        left: 9px;
        z-index: 2;
      }

      img:nth-of-type(2) {
        position: absolute;
        bottom: 0px;
        left: 0px;
        z-index: 3;
      }

      img:nth-of-type(3) {
        position: absolute;
        bottom: 0px;
        right: 0px;
        z-index: 1;
      }
    }

    .operator-info-image {
      width: 38px;
      height: 38px;
      border-radius: 100%;

      img {
        width: 38px;
        height: 38px;
        background-color: var(--secondary-text-color);
        object-fit: cover;
        border-radius: 100%;
      }

      svg {
        width: 38px;
        height: 38px;
        object-fit: contain;
        border-radius: 100%;

        #botbg {
          fill: var(--border-color-33);
        }

        .botclip,
        #botclip {
          fill: var(--primary-text-color);
        }
      }
    }
  }

  &-labels {
    padding-left: 12px;
    padding-right: 8px;
    flex-grow: 1;
    max-width: calc(100% - 56px);

    .conversation-item-title {
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      margin-bottom: 0px;
      color: var(--primary-text-color);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 100%;
    }

    .conversation-item-description {
      font-size: 14px;
      line-height: 21px;
      font-weight: 400;
      color: var(--secondary-text-color);
    }
  }

  &-indicator {
    width: 14px;
    height: 14px;
    object-fit: contain;

    path {
      fill: var(--primary-color);
    }
  }
}