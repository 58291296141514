.checkbox-form-item {
  .form-control {
    margin-top: 0px;
    cursor: pointer;
    display: grid;
    grid-template-columns: 1em auto;
    gap: 10px;
    text-align: left;

    span {
      margin-left: 7px;
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      margin-top: 2px;
      color: var(--primary-text-color);
      user-select: none;

      .privacy-link {
        color: var(--primary-color);
        cursor: pointer;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        text-decoration: underline;
        margin-left: 1px;
        margin-right: 3px;
        margin-top: 0px;
      }
    }
  }

  .form-control + .form-control {
    margin-top: 1em;
  }

  .form-control--disabled {
    color: var(--form-control-disabled);
    cursor: not-allowed;
  }

  input[type="checkbox"] {
    appearance: none;
    -webkit-appearance: none;
    background-color: var(--form-background);
    margin: 0;
    cursor: pointer;
    font: inherit;
    color: currentColor;
    width: 22px;
    height: 22px;
    margin: 1px;
    box-shadow: 0px 0px 0px 1px var(--border-color);
    border-radius: 0.15em;
    transform: translateY(-0.075em);
    display: grid;
    place-content: center;
  }

  input[type="checkbox"]::before {
    cursor: pointer;
    content: "";
    width: 14px;
    height: 14px;
    clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
    transform: scale(0);
    transform-origin: bottom left;
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em var(--primary-color);
    background-color: CanvasText;
  }

  input[type="checkbox"]:checked::before {
    transform: scale(1);
  }
}
