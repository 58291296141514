.main-menu {
  margin: auto;
  margin-top: -90px;
  z-index: 2;
  position: relative;
  max-width: 600px;

  .widget-menu-buttons {
    z-index: 2;
  }

  &--inner {
    animation: menuFadeIn;
    animation-duration: 0.45s;
    padding: 18px;
    padding-top: 25px;
  }
}

.main-app--anifirst {
  .main-menu {
    &--inner {
      animation: menuFadeIn;
      animation-duration: 0.6s;
    }
  }

  .widget-header-main {
    animation: menuFadeIn;
    animation-duration: 0.6s;
  }

  .tab-bar {
    animation: fadeInUp;
    animation-duration: 0.6s;
    animation-fill-mode: forwards;
  }
}