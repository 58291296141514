.conversations {
  height: calc(100vh - 60px);
  overflow: auto;
  animation: menuFadeIn;
  animation-duration: 0.45s;

  .action-button svg {
    margin-right: 0px;
    margin-left: 10px;
  }
}

.conversations-no-conversations {
  text-align: center;
  padding: 20px;
  font-size: 14px;
  line-height: 16px;
  font-weight: 400;
  color: var(--secondary-text-color);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: calc(100% - 80px);

  .title {
    font-size: 16px;
    line-height: 18px;
    font-weight: 600;
    color: var(--primary-text-color);
    margin-bottom: 8px;
  }

  .description {
    font-size: 14px;
    line-height: 16px;
    font-weight: 400;
    color: var(--secondary-text-color);
  }

  .no-messages-yet {
    width: 26px;
    height: auto;
    margin-bottom: 20px;
    fill: var(--primary-text-color);
  }

  .action-button {
    margin-top: 30px;
  }
}

.conversations-load-more {
  display: flex;
  justify-content: center;
  margin-bottom: 200px;
  margin-top: 20px;

  .gleap-loading {
    width: 34px;
    height: 34px;
  }
  .gleap-loading div {
    width: 34px;
    height: 34px;
    border-width: 3px;
  }
}
