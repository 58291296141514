.survey-intro {
  padding-top: 30px;
  padding-left: 30px;
  padding-right: 30px;
  margin-bottom: -10px;

  h1 {
    color: var(--primary-text-color);
    font-size: 25px;
    line-height: 1.3;
    text-align: left;
    font-weight: 600;
    margin-bottom: 10px;
    margin-top: 0px;

    @media screen and (max-device-width: 600px) {
      font-size: 22px;
    }
  }

  p {
    color: var(--primary-text-color);
    font-size: 16px;
    line-height: 19px;
    text-align: left;
    font-weight: 400;
    margin-bottom: 4px;
    margin-top: 10px;

    @media screen and (max-device-width: 600px) {
      font-size: 15px;
    }
  }
}
