.bot-action-text-dropdown {
  display: flex;
  flex-direction: column;
  width: 100%;
  display: flex;

  &-label {
    font-size: 14px;
    font-weight: 400;
    line-height: 1.3;
    margin-bottom: 8px;
    color: var(--primary-text-color);
  }

  &-innercontainer {
    width: 100%;
    position: relative;

    .caret {
      position: absolute;
      top: 12px;
      right: 13px;
      width: 10px;
      height: auto;
    }

    select {
      cursor: pointer;
      width: 100%;
      font-size: 14px;
      line-height: 1.3;
      appearance: none;
      padding: 11px 12px;
      outline: 0;
      flex-grow: 1;
      color: var(--primary-text-color);
      border: 1px solid var(--border-color);
      background-color: var(--background-color);
      box-sizing: border-box;
      font-weight: 400;
      border-radius: var(--border-radius-50);

      &::placeholder {
        color: var(--primary-text-color);
        opacity: 0.4;
      }

      @media screen and (max-device-width: 600px) {
        font-size: 16px;
      }
    }

    &:focus,
    &:hover {
      select {
        outline: none !important;
        border: 1px solid var(--primary-color-AA) !important;
      }

      .caret {
        fill: var(--primary-color) !important;
      }
    }
  }
}
