.capture-buttons {
  display: flex;
  flex-wrap: nowrap;
  max-width: 100%;
  justify-content: flex-start;

  .capture-button {
    border-radius: var(--border-radius-60);
    background-color: var(--background-color-darker);
    box-sizing: border-box;
    padding: 8px 12px;
    width: auto;
    min-width: 0px;
    flex-shrink: 1;
    margin-top: 0px;
    margin-right: 8px;
    margin-bottom: 0px;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    height: 39px;

    &--screenshot {
      flex-shrink: 0;
      max-width: 70%;
    }

    svg {
      width: 19px;
      height: 19px;
      object-fit: contain;
      flex-shrink: 0;

      path,
      g {
        fill: var(--primary-text-color);
      }
    }

    span {
      font-size: 14px;
      line-height: 19px;
      font-weight: 600;
      color: var(--primary-text-color);
      font-weight: 400;
      margin-left: 8px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &:last-of-type {
      margin-right: 0px;
    }

    @media (hover: hover) {
      &:hover {
        background-color: var(--background-color-dark);
      }
    }
  }

  .capture-added-button {
    border-radius: var(--border-radius-50);
    background-color: var(--background-color-darker);
    box-sizing: border-box;
    padding: 6px 12px;
    padding-right: 8px;
    width: auto;
    margin-top: 5px;
    margin-right: 8px;
    margin-bottom: 0px;
    -webkit-tap-highlight-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    height: 39px;

    svg {
      width: 19px;
      height: 19px;
      object-fit: contain;

      path,
      g {
        fill: var(--primary-text-color);
      }
    }

    span {
      font-size: 14px;
      line-height: 19px;
      font-weight: 600;
      color: var(--primary-text-color);
      font-weight: 400;
      margin-left: 8px;
    }

    &:last-of-type {
      margin-right: 0px;
    }

    &-remove {
      cursor: pointer;
      margin-left: 5px;
      width: 27px;
      height: 27px;
      border-radius: var(--border-radius-25);
      display: flex;
      justify-content: center;
      align-items: center;

      @media (hover: hover) {
        &:hover {
          background-color: var(--background-color-dark);
        }
      }
    }
  }
}

[dir="rtl"] .capture-buttons .capture-button {
  margin-left: 0px;
  margin-right: 0px;

  &:last-of-type {
    margin-right: 8px !important;
  }
}

[dir="rtl"] .capture-added-button {
  padding: 6px 12px 6px 8px;
}

[dir="rtl"] .capture-buttons .capture-added-button span,
[dir="rtl"] .capture-buttons .capture-button span {
  margin-left: 0px !important;
  margin-right: 8px;
}

[dir="rtl"] .capture-buttons .capture-added-button-remove {
  margin-left: 0px;
  margin-right: 5px;
}

.tooltip {
  position: relative;
}

.tooltip .top {
  min-width: 150px;
  top: -12px;
  left: 50%;
  transform: translate(-50%, -100%);
  padding: 10px;
  color: var(--primary-text-color);
  background-color: var(--background-color);
  font-weight: normal;
  font-size: 13px;
  border-radius: var(--border-radius-50);
  text-align: center;
  position: absolute;
  z-index: 99999999;
  box-sizing: border-box;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 0px 0px 1px,
    rgba(0, 0, 0, 0.06) 0px 2px 8px;
  display: none;
}

.tooltip:hover .top {
  display: block;
}

.tooltip .top i {
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -12px;
  width: 24px;
  height: 12px;
  overflow: hidden;
}

.tooltip .top i::after {
  content: "";
  position: absolute;
  width: 12px;
  height: 12px;
  left: 50%;
  transform: translate(-50%, -50%) rotate(45deg);
  background-color: var(--background-color);
  box-shadow: rgba(0, 0, 0, 0.08) 0px 0px 0px 1px,
    rgba(0, 0, 0, 0.06) 0px 2px 8px;
}
