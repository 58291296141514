.bot-buttons {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  flex-wrap: wrap;
  padding: 19px;
  padding-top: 0px;
  padding-bottom: 7px;
}

.bot-button {
  border: none;
  outline: none;
  cursor: pointer;
  background-color: var(--primary-color-22);
  color: var(--primary-color);
  border-radius: var(--border-radius-50);
  min-height: 38px;
  line-height: 18px;
  padding: 10px 10px;
  margin: 5px;
  font-size: 14px;
  font-weight: normal;
  text-align: left;

  &:hover {
    background-color: var(--primary-color);
    color: var(--primary-contrast-color);
  }
}
