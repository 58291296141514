.powered-by {
  padding: 20px;
  padding-top: 10px;
  margin-bottom: 80px;

  &--inner {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    margin: 0px;
  }

  span {
    font-weight: 400;
    font-size: 13px;
    user-select: none;
    color: var(--secondary-text-color);
    margin-right: 4px;
    margin-left: 4px;
  }

  svg.bolt {
    height: 13px;
    width: auto;
  }

  svg.logo {
    height: 14px;
    width: auto;
    margin-top: 2px;

    g {
      fill: var(--secondary-text-color);
    }
  }

  &--hidden {
    padding-top: 0px;
    padding-bottom: 25px;
  }
}