.bot-action-rating {
  display: flex;
  flex-direction: column;
  width: 100%;
  display: flex;

  &-label {
    font-size: 14px;
    font-weight: 400;
    line-height: 1.3;
    margin-bottom: 8px;
    color: var(--primary-text-color);
  }

  &-innercontainer {
    width: 100%;
    position: relative;

    .rating-form-item-option {
      width: 46px;
      min-width: 44px;
      max-width: 44px;
      line-height: 42px;
      min-height: 44px;
      max-height: 44px;
    }
  }

  &-option {
    margin-left: 5px;
    margin-right: 5px;
    font-size: 34px;
    transition: all 0.2s ease-in-out;

    &:hover {
      cursor: pointer;
      transform: scale(1.2);
    }
  }

  &-items {
    display: flex;
    justify-content: center;
    margin-top: 10px;
    margin-bottom: 10px;
  }
}
