.rating-form-item {
  &-items {
    display: flex;
    justify-content: center;
  }

  &-option {
    cursor: pointer;
    padding: 0;
    border: 1px solid var(--primary-color-55);
    background-color: var(--primary-color-07);
    color: var(--primary-color);
    border-radius: var(--border-radius-25);
    text-align: center;
    margin: 3px;
    width: 54px;
    min-width: 54px;
    max-width: 54px;
    line-height: 52px;
    min-height: 54px;
    max-height: 54px;
    font-size: 28px;
    font-weight: 500;

    &:hover,
    &--active {
      background-color: var(--primary-color-22);
      border: 1px solid var(--primary-color);
      color: var(--primary-color);
    }
  }
}
